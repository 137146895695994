<template>
  <div class="profile-page col-md-10 offset-1">
    <CCard>
      <CCardBody>
        <h5 class="text-primary">Profile Page</h5>
        <CRow>
          <CCol md="5">
            <CRow class="mb-3">
              <label class="col-md-12 required">Profile Page Template</label>
              <div class="col-md-12">
                <Select
                  name="profile"
                  :value="partnerInfo.profile"
                  :options="options['profileTemplate'] || []"
                  @change="handleChangeSelect"
                />
              </div>
            </CRow>
            <div>
              <CButton color="primary" class="mx-1" @click="createProfile"
                >Create</CButton
              >
              <CButton color="primary" class="mx-1" @click="navToHome" variant="outline"
                >Cancel</CButton
              >
            </div>
          </CCol>
          <CCol md="7">
            <div class="profile-preview text-center">
              <img
                v-if="partnerInfo.profile"
                :src="partnerInfo.profile.thumbnail"
                width="90%"
              />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import Vue from "vue";
import SLCProfile from "@/components/reusable/ProfilePage/SLCPage";
import DOHProfile from "@/components/reusable/ProfilePage/DOHPage";
import NHSProfile from "@/components/reusable/ProfilePage/NHSPage";
import Select from "@/components/reusable/Fields/Select.vue";

import { CONSTANT } from "@/helpers/helper";
import { mapActions } from "vuex";
Vue.component("SLCProfile", SLCProfile);
Vue.component("DOHProfile", DOHProfile);
Vue.component("NHSProfile", NHSProfile);
export default {
  name: "AddProfilePage",
  components: {
    Select,
  },
  data() {
    return {
      partnerInfo: {},
      partners: CONSTANT.partners,
    };
  },
  computed: {
    options() {
      return {
        profileTemplate: this.partners.map((v) => ({
          code: v.partner_id,
          label: v.name,
          _rawData: v,
          component: v.component,
          thumbnail: v.thumbnail,
        })).filter(v=>v.code === 1),
      };
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    createProfile() {
      if (!this.partnerInfo?.profile) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please Select Profile Template!",
        });
        return;
      }

      this.showToast({
        class: "bg-success text-white",
        message: "Profile Template created!",
      });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.partnerInfo, name, value);
    },
    navToHome(){
        this.$router.push("/list-course")
    }
  },
};
</script>
<style lang="scss" scoped>
.profile-preview {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
